import React from "react";
import styles from "./vpTemplateNav.module.scss";

const VpTemplateNav = () => {
  return (
    <>
      <div className={styles.navContainer}>Nav</div>
    </>
  );
};

export default VpTemplateNav;
