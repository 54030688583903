import React from 'react';
import styles from './updates.module.scss';

import { AiOutlineHeart } from 'react-icons/ai';
import { BiComment } from 'react-icons/bi';

const Updates = () => {
  const updatesArray = [
    {
      title: 'ACFN - Networking to grow our business',
      desc: 'The largest convention for our industry is the annual ATMIA conference Taking place at New Orleans, LA this year February 7th – 9th. The conference sponsors and presenters shareNew productsNew... Read more',
      authorDetails: {
        name: 'Jeffrey Kerr',
        sub: 'ACFN Franchised Inc.',
        img: 'https://uploads.republic.com/p/users/avatars/small_2x/001/278/505/1278505-1659738096-c9a6bedf9704a50c6baeb4ab9cda706ef07929ba.jpg',
      },
      date: 'Jan 23',
      year: '2023',
      type: 'blog',
      like: 6,
      comment: 1,
    },
    {
      title: 'Special Incentives Attracting Investment',
      desc: 'Special incentives were added recently to our campaign page and announced to all current prospective franchisees. Since the announcement four new franchisees have joined our business and I am... Read more',
      authorDetails: {
        name: 'Jeffrey Kerr',
        sub: 'ACFN Franchised Inc.',
        img: 'https://uploads.republic.com/p/users/avatars/small_2x/001/278/505/1278505-1659738096-c9a6bedf9704a50c6baeb4ab9cda706ef07929ba.jpg',
      },
      date: 'Jan 9',
      year: '2023',
      type: 'blog',
      like: 6,
      comment: 1,
    },
    {
      title: 'Here we grow again - the deals keep coming$',
      desc: 'ACFN’s in-house marketing team, led by our VP of Sales and Marketing Jim Diltz, continues to shine closing quality deals for our franchise owners and growing our business. Jim joined ACFN in 2006,... Read more',
      authorDetails: {
        name: 'Jeffrey Kerr',
        sub: 'ACFN Franchised Inc.',
        img: 'https://uploads.republic.com/p/users/avatars/small_2x/001/278/505/1278505-1659738096-c9a6bedf9704a50c6baeb4ab9cda706ef07929ba.jpg',
      },
      date: 'Dec 14',
      year: '2022',
      type: 'blog',
      like: 4,
      comment: 2,
    },
    {
      title: 'Legacy Business Meets Cutting Edge Innovation USD & BTC',
      desc: 'Legacy Business Meets Cutting Edge Innovation USD &amp; BTC At ACFN we are always working to increase revenue and profits. One of the best ways to achieve both is to find new ways to monetize our... Read more',
      authorDetails: {
        name: 'Jeffrey Kerr',
        sub: 'ACFN Franchised Inc.',
        img: 'https://uploads.republic.com/p/users/avatars/small_2x/001/278/505/1278505-1659738096-c9a6bedf9704a50c6baeb4ab9cda706ef07929ba.jpg',
      },
      date: 'Dec 7',
      year: '2022',
      type: 'blog',
      like: 5,
      comment: 4,
    },
    {
      title: 'ACFN Innovation Working Pharma-Vend',
      desc: 'Over our years in business we developed a very large and valuable list of 2,700 corporate client. In addition to continuing to add new relationships both organically and by acquisition our... Read more',
      authorDetails: {
        name: 'Jeffrey Kerr',
        sub: 'ACFN Franchised Inc.',
        img: 'https://uploads.republic.com/p/users/avatars/small_2x/001/278/505/1278505-1659738096-c9a6bedf9704a50c6baeb4ab9cda706ef07929ba.jpg',
      },
      date: 'Nov 22',
      year: '2022',
      type: 'blog',
      like: 7,
      comment: 2,
    },
    {
      title:
        'Special Incentive for ACFN Crowdfunding – double-dipping is now encouraged!',
      desc: 'We just received approval from counsel to offer full credit for any amount invested in our Crowdfunding Campaign towards the purchase of an ACFN franchise. The offer is valid up to 12 months from... Read more',
      authorDetails: {
        name: 'Jeffrey Kerr',
        sub: 'ACFN Franchised Inc.',
        img: 'https://uploads.republic.com/p/users/avatars/small_2x/001/278/505/1278505-1659738096-c9a6bedf9704a50c6baeb4ab9cda706ef07929ba.jpg',
      },
      date: 'Nov 15',
      year: '2022',
      type: 'blog',
      like: 4,
      comment: 3,
    },
    {
      title: 'Raised 400% 😱',
      date: 'Nov 15',
      year: '2022',
      type: 'announcement',
    },
    {
      title: 'Landmark Deals Signed in the last 30 days',
      desc: 'In addition to formalizing our Preferred Vendor status with the largest hospitality management group in the world Aimbridge we also secured landmark deals for individual locations in the last 30... Read more',
      authorDetails: {
        name: 'Jeffrey Kerr',
        sub: 'ACFN Franchised Inc.',
        img: 'https://uploads.republic.com/p/users/avatars/small_2x/001/278/505/1278505-1659738096-c9a6bedf9704a50c6baeb4ab9cda706ef07929ba.jpg',
      },
      date: 'Nov 8',
      year: '2022',
      type: 'blog',
      like: 7,
      comment: 2,
    },
    {
      title: 'ACFN campaign video now streaming',
      desc: 'Happy Halloween! We just completed and posted our campaign video. To see the video please visit our campaign page and click the new play button added to the first image on the page. Hope you enjoy... Read more',
      authorDetails: {
        name: 'Jeffrey Kerr',
        sub: 'ACFN Franchised Inc.',
        img: 'https://uploads.republic.com/p/users/avatars/small_2x/001/278/505/1278505-1659738096-c9a6bedf9704a50c6baeb4ab9cda706ef07929ba.jpg',
      },
      date: 'Nov 1',
      year: '2022',
      type: 'blog',
      like: 5,
      comment: 0,
    },
    {
      title: 'ACFN signs landmark deal with Aimbridge Hospitality',
      desc: 'Here We Grow Again$October 20th 2022 ACFN signs Preferred Vendor Agreement with the largest hospitality group in the world Aimbridge Hospitality. ACFN is the only company providing ATM services... Read more',
      authorDetails: {
        name: 'Jeffrey Kerr',
        sub: 'ACFN Franchised Inc.',
        img: 'https://uploads.republic.com/p/users/avatars/small_2x/001/278/505/1278505-1659738096-c9a6bedf9704a50c6baeb4ab9cda706ef07929ba.jpg',
      },
      date: 'Oct 21',
      year: '2022',
      type: 'blog',
      like: 14,
      comment: 3,
    },
    {
      title: 'Raised 200% 🔥',
      date: 'Oct 16',
      year: '2022',
      type: 'announcement',
    },
    {
      title: 'Raised 100% 💥',
      date: 'Oct 7',
      year: '2022',
      type: 'announcement',
    },
    {
      title: 'Raised 90% 🏁',
      date: 'Oct 7',
      year: '2022',
      type: 'announcement',
    },
    {
      title: 'Raised 50% 🍹',
      date: 'Oct 7',
      year: '2022',
      type: 'announcement',
    },
    {
      title: 'Raised 30% 🎂',
      date: 'Oct 6',
      year: '2022',
      type: 'announcement',
    },
    {
      title: 'Launched 🚀',
      date: 'Oct 5',
      year: '2022',
      type: 'announcement',
    },
  ];

  return (
    <>
      <div className={styles.updatesContainer}>
        <div className={styles.updatesWidth}>
          {/* <div className={styles.updateCard}> */}
          {updatesArray.map((item, index) => {
            return (
              <>
                {item.type === 'blog' ? (
                  <div className={styles.updateCard}>
                    <div className={styles.updateDate}>
                      <div>{item.date}</div>
                      <div>{item.year}</div>
                    </div>
                    <h1 className={styles.updateTitle}>{item.title}</h1>
                    <p className={styles.updateDesc}>{item.desc}</p>
                    <div className={styles.authorDetails}>
                      <div className={styles.authorImg}>
                        <img src={item.authorDetails?.img} alt="" />
                      </div>
                      <div>
                        <div className={styles.authorName}>
                          {item.authorDetails?.name}
                        </div>
                        <div className={styles.authorSub}>
                          {item.authorDetails?.sub}
                        </div>
                      </div>
                    </div>
                    <div className={styles.updateFooter}>
                      <div className={styles.likeContainer}>
                        <AiOutlineHeart style={{ fontSize: '19px' }} />
                        <div>&nbsp;Like ({item.like})</div>
                      </div>
                      <div className={styles.commentContainer}>
                        <BiComment style={{ fontSize: '19px' }} />
                        <div>&nbsp;Comment ({item.comment})</div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={styles.updateCard} style={{ border: 'none' }}>
                    <div className={styles.updateDate}>
                      <div>{item.date}</div>
                      <div>{item.year}</div>
                    </div>
                    <h1 className={styles.updateTitle}>{item.title}</h1>
                  </div>
                )}
              </>
            );
          })}
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default Updates;
