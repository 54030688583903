import React from 'react';
import styles from './discussion.module.scss';

import { AiFillHeart } from 'react-icons/ai';
import { MdEdit } from 'react-icons/md';

const Discussion = () => {
  const comments = [
    {
      name: 'Bonnie Denham',
      avatar:
        'https://uploads.republic.com/p/users/avatars/small_2x/001/339/184/1339184-1675444010-1d231bb95f14b65b51a9e878b470b2b2c78f7ee2.jpg',
      comment:
        'What is a “credit towards franchise cost” and what do I do with the credit? How is a credit used and what can it be used for?',
      date: '12/12/2020',
      time: '12:00 PM',
      reply: [
        {
          name: 'Jeffrey Kerr',
          avatar:
            'https://uploads.republic.com/p/users/avatars/small_2x/001/278/505/1278505-1659738096-c9a6bedf9704a50c6baeb4ab9cda706ef07929ba.jpg',
          comment:
            '@bonnie-denham This is for investors that are also interested in becoming an ACFN franchisee or franchisees that also want to be investors. Full credit is given for the amount invested in our Crowdfunding campaign in the form of discount against our franchise fee. As an example: a person investing $5,000 in our Crowdfunding campaign would get $5,000 off the cost of our franchise fee.',
          date: '12/12/2020',
          time: '12:00 PM',
        },
      ],
    },
    {
      name: 'William Beatty',
      avatar:
        'https://assets.republic.com/assets/default/user/small_2x-a1557887c82de542dff53638dccb748318ac68c53c156c6aea7a28d9ebb92b84.svg',
      comment: 'Do I receive some sort of annual dividend for my investments?',
      date: '12/12/2020',
      time: '12:00 PM',
      reply: [
        {
          name: 'Jeffrey Kerr',
          avatar:
            'https://uploads.republic.com/p/users/avatars/small_2x/001/278/505/1278505-1659738096-c9a6bedf9704a50c6baeb4ab9cda706ef07929ba.jpg',
          comment:
            '@william-beatty-2 Thank you for your interest in ACFN! Our offering uses the Simple Agreement for Future Equity ("SAFE") agreement so you receive equity in the future when we achieve a liquidity event. All the details are in the SAFE agreement in the documents section on our campaign page.',
          date: '12/12/2020',
          time: '12:00 PM',
        },
      ],
    },
    // {
    //   name: 'John Doe',
    //   avatar: 'https://i.pravatar.cc/150?img=1',
    //   comment:
    //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    //   date: '12/12/2020',
    //   time: '12:00 PM',
    //   reply: [
    //     {
    //       name: 'John Doe',
    //       avatar: 'https://i.pravatar.cc/150?img=1',
    //       comment:
    //         'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    //       date: '12/12/2020',
    //       time: '12:00 PM',
    //     },
    //   ],
    // },
  ];

  return (
    <>
      <div className={styles.discussionContainer}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className={styles.bottomTitle}>Discussions</div>
          <hr className={styles.blueBar} />
        </div>
        <p>
          Ask questions and share feedback with the ACFN Franchised Inc. team
          below. If you have support related questions for Republic, please
          contact investors@republic.co.
        </p>
        {comments.map((item) => {
          return (
            <div className={styles.commentWrapper}>
              <div className={styles.commentItem}>
                <div className={styles.commenterStyle}>
                  <img src={item.avatar} alt="" />
                  <div className={styles.nameStyle}>
                    <div>{item.name}</div>
                    <div>3 days ago</div>
                  </div>
                </div>
                <div className={styles.commentStyle}>{item.comment}</div>
                <div className={styles.likeCommentIcons}>
                  <div className={styles.likeIcon}>
                    <AiFillHeart />
                    <div>Like</div>
                  </div>
                  <div className={styles.commentIcon}>
                    <MdEdit />
                    <div>Reply</div>
                  </div>
                </div>
              </div>
              {item.reply.map((item1) => {
                return (
                  <div className={styles.replyContainer}>
                    <div className={styles.replyerStyle}>
                      <img src={item1.avatar} alt="" />
                      <div className={styles.nameStyle}>
                        <div>
                          {item1.name}{' '}
                          <span className={styles.replyStyle}>
                            {item1.comment}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={styles.likeCommentIcons}
                      style={{ paddingLeft: '40px' }}
                    >
                      <div
                        className={styles.likeIcon}
                        style={{ paddingLeft: '0px' }}
                      >
                        <AiFillHeart />
                        <div>Like</div>
                      </div>
                      <div className={styles.commentIcon}>
                        <div>Reply</div>
                      </div>

                      <div className={styles.commentIcon}>
                        <div>3 mins ago</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Discussion;
